import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42')
];

export const server_loads = [6,7,8,5];

export const dictionary = {
		"/(hq)": [~11,[4]],
		"/(hq)/admin": [~32,[4,6]],
		"/(hq)/admin/admins": [~33,[4,6]],
		"/(hq)/admin/connections": [~34,[4,6]],
		"/(hq)/admin/division_staff": [~35,[4,6]],
		"/(hq)/admin/facilities": [~36,[4,6]],
		"/(hq)/callback": [~37,[4]],
		"/(docs)/docs": [9,[2,3]],
		"/(docs)/docs/[...slug]": [10,[2,3]],
		"/(hq)/logout": [38,[4]],
		"/(hq)/switch_hq": [39,[4,7]],
		"/(hq)/tvc": [~40,[4,8]],
		"/(hq)/tvc/apply/transfer": [~41,[4,8]],
		"/(hq)/tvc/apply/visit": [~42,[4,8]],
		"/(hq)/[id]": [~12,[4,5]],
		"/(hq)/[id]/edit": [~13,[4,5]],
		"/(hq)/[id]/events": [~14,[4,5]],
		"/(hq)/[id]/events/[eventId]": [~15,[4,5]],
		"/(hq)/[id]/events/[eventId]/edit": [~16,[4,5]],
		"/(hq)/[id]/events/[eventId]/signups": [~17,[4,5]],
		"/(hq)/[id]/events/[eventId]/signups/roster": [~18,[4,5]],
		"/(hq)/[id]/manage": [~19,[4,5]],
		"/(hq)/[id]/manage/createrole": [~20,[4,5]],
		"/(hq)/[id]/manage/editrole/[roleId]": [~21,[4,5]],
		"/(hq)/[id]/resources": [~22,[4,5]],
		"/(hq)/[id]/roster": [~23,[4,5]],
		"/(hq)/[id]/training": [~24,[4,5]],
		"/(hq)/[id]/training/plans": [~26,[4,5]],
		"/(hq)/[id]/training/plans/requests": [~27,[4,5]],
		"/(hq)/[id]/training/requests": [~28,[4,5]],
		"/(hq)/[id]/training/transcript/[studentId]": [~29,[4,5]],
		"/(hq)/[id]/training/[sessionId]": [~25,[4,5]],
		"/(hq)/[id]/tvc": [~30,[4,5]],
		"/(hq)/[id]/tvc/cases/[caseId]": [~31,[4,5]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';